// HMIMenu 菜单用js
//
//  依赖
//     vue.min.js
//
//  历史
//      创建    LEE     2020/12/22
//=============================================================================
// 主菜单处理函数
const HMIMenu = {
    // 菜单获取事件
    getEl : function(e) {
        // 检查是否存在
        if (!e) e = window.event;
        // 获取对象
        if (e.target) return e.target;
        else if (e.srcElement) return e.srcElement;

        // 空
        return null;
    },
    // 检查菜单合并状态
    menuCollapse: function(el) {
        let sV = el.innerHTML;
        // 检查字符串是否存在关闭状态
        if(sV.search(/\x2B/) == -1) {
            return false;
        }
        return true;
    },
    // 菜单合并状态切换
    menuSwitch: function(el) {
        let sV = el.innerHTML;
        // 检查字符串是否存在关闭状态
        if(sV.search(/\x2B/) != -1) {
            sV = sV.replace('+', '-');
        }
        else {
            sV = sV.replace('-', '+');
        }
        // 设置内容
        el.innerHTML = sV;
    },
    // 菜单设为选中状态
    menuSelect: function (e) {
        // 获取事件对象
        let el = this.getEl(e);
        // 检查是否有效
        if(el) {
            // 切换
            this.menuSwitch(el);
        }
    },
    // 获取菜单元素
    getMenuContainer: function (el) {
        // 检查是否为空
        if(el == null) return null;
        // 检查是否为DIV
        if(el.tagName.toUpperCase() === 'DIV') {
            // 获取真正的容器
            return el;
        }
        // 获取上级
        el = el.parentNode;
        // 检查是否为DIV
        if(el.tagName.toUpperCase() === 'DIV') {
            // 获取真正的容器
            return el;
        }
        // 获取上级
        el = el.parentNode;
        // 检查是否为DIV
        if(el.tagName.toUpperCase() === 'DIV') {
            // 获取真正的容器
            return el;
        }
        // 无效
        return null;
    },
    // 菜单设为高亮状态
    menuHover: function (e) {
        // 获取事件对象
        let el = this.getMenuContainer(this.getEl(e));
        // 检查是否有效
        if(el) {
            // 设置类名
            el.className = 'hl';
        }
    },
    // 菜单恢复
    menuRestore: function (e) {
        // 获取事件对象
        let el = this.getMenuContainer(this.getEl(e));
        // 检查是否有效
        if(el) {
            // 第一个内部控件
            let childContent = el.firstChild;
            // 检查是否有效
            if(childContent) {
                // 状态
                let collapse = this.menuCollapse(childContent);
                // 检查是否选中状态
                if(!collapse) {
                    // 设置类名
                    el.className = 'sl';
                }
                else {
                    // 设置类名
                    el.className = 'sl';
                }
            }
        }
    },
    // 控件设为高亮状态
    ctrlHover: function (e) {
        // 获取事件对象
        let el = this.getEl(e);
        // 检查是否有效
        if(el) {
            el.className = 'ctrlItemHL';
        }
    },
    // 控件恢复
    ctrlRestore: function (e) {
        // 获取事件对象
        let el = this.getEl(e);
        // 检查是否有效
        if(el) {
            el.className = 'ctrlItem';
        }
    },
    // 工具栏设为高亮状态
    toolHover: function (e) {
        // 获取事件对象
        let el = this.getEl(e);
        // 检查是否有效
        if(el) {
            el.className = 'operateItemHL';
        }
    },
    // 工具栏设为恢复状态
    toolRestore: function (e) {
        // 获取事件对象
        let el = this.getEl(e);
        // 检查是否有效
        if(el) {
            el.className = 'operateItem';
        }
    },
    // 布局工具设为高亮状态
    alignHover: function (e) {
        // 获取事件对象
        let el = this.getEl(e);
        // 检查是否有效
        if(el) {
            el.className = 'alignItemHL';
        }
    },
    // 布局工具设为恢复状态
    alignRestore: function (e) {
        // 获取事件对象
        let el = this.getEl(e);
        // 检查是否有效
        if(el) {
            el.className = 'alignItem';
        }
    },
};
// 外部接口暴露
export default HMIMenu;