<template>
  <el-dialog
    title="数据编辑"
    top="60px"
    :modal="false"
    :visible.sync="dlgVisible"
    width="700px"
  >
    <div class="grid-main">
      <el-row :gutter="3">
        <el-col :span="2"><div class="grid-title">选择</div></el-col>
        <el-col :span="6"><div class="grid-title">参数</div></el-col>
        <el-col :span="6"><div class="grid-title">标签名称</div></el-col>
        <el-col :span="4"><div class="grid-title">绘制颜色</div></el-col>
        <el-col :span="3"><div class="grid-title">最小值</div></el-col>
        <el-col :span="3"><div class="grid-title">最大值</div></el-col>
      </el-row>
      <div v-for="item in getCurvesData()" :key="item.idx" class="grid-row">
        <el-row :gutter="6">
          <el-col :span="2"
            ><div class="grid-cmd">
              <el-checkbox v-model="item.selected"></el-checkbox></div
          ></el-col>
          <el-col :span="6">
            <div class="grid-content">
              <!-- 变量参数显示 -->
              <el-cascader
                v-model="item.param"
                :options="getVariableOptions()"
                :show-all-levels="false"
                size="mini"
                collapse-tags
              ></el-cascader>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-text">
              <input type="text" v-model="item.text" class="attrWL" />
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-color-text">
              <input
                type="text"
                v-model="item.color"
                readonly="true"
                class="attrRSF"
              />
            </div>
            <div class="grid-color">
              <el-color-picker
                v-model="item.color"
                size="mini"
                :predefine="constant.predefineColors"
              ></el-color-picker>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-text">
              <input type="text" v-model="item.min" class="attrWL" />
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-text">
              <input type="text" v-model="item.max" class="attrWL" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addCurveData()" size="mini">新增行</el-button>
      <el-button @click="removeLstData()" size="mini">删除行</el-button>
      <el-button @click="dlgVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit()" size="mini"
        >保存退出</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIPlan from "../util/draw/HMIPlanData.js";
import constant from "../util/constant";

// 当前组件属性
export default {
  // 属性
  props: ["visible", "name"],
  // 数据
  data: function () {
    return {
      // 对话框是否显示
      dlgVisible: this.visible,
      // 当前控件指针
      currentCtrl: null,
      // 所有的曲线数据
      listAllCurves: [],
      constant: constant,
    };
  },
  // 函数
  methods: {
    // 获取当前选择的控件
    getCurrentCtrl: function () {
      // 设置当前选中的页面
      let page = null;
      // 检查当前控件指针是否已经获取
      if (null === this.currentCtrl) {
        // 设置当前选中的页面
        page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
        // 检查是否选中控件
        for (let idx = 0; idx < page.ctrls.length; idx++) {
          // 获取选中控件
          if (page.ctrls[idx].selected) {
            // 获取控件
            this.currentCtrl = page.ctrls[idx];
            // 跳出
            break;
          }
        }
      }
      // 当前选中控件
      return this.currentCtrl;
    },
    // 获取所有的曲线数据
    getCurvesData: function () {
      // 检查是否获取了控件中的数据列表
      if (this.listAllCurves.length === 0) {
        // 设置当前选中的控件
        let ctrl = this.getCurrentCtrl();
        let dataAll = null;
        let dateItem = null;
        // 检查控件是否存在
        if (null != ctrl) {
          // 检查控件类型
          if (ctrl.type === HG.ControlType.curves) {
            // 获取数据
            dataAll = ctrl.curvesData;
          } else if (ctrl.type === HG.ControlType.columns) {
            // 获取数据
            dataAll = ctrl.columnsData;
          } else if (ctrl.type === HG.ControlType.pies) {
            // 获取数据
            dataAll = ctrl.piesData;
          }

          // 检查数据是否存在
          if (dataAll) {
            // 检查是否选中控件
            for (let idx = 0; idx < dataAll.length; idx++) {
              // 获取当前曲线数据
              dateItem = dataAll[idx];
              // 添加曲线数据
              this.listAllCurves.push({
                selected: false,
                idx: dateItem.idx,
                param: dateItem.param,
                text: dateItem.text,
                color: dateItem.color,
                min: dateItem.min,
                max: dateItem.max,
              });
            }
            // 空内容
            if (this.listAllCurves.length === 0) {
              // 添加曲线数据
              this.addCurveData();
              this.addCurveData();
              this.addCurveData();
            }
          }
        }
      }
      // 返回列表
      return this.listAllCurves;
    },
    // 获取示例颜色
    getCurveSampleColor: function (idx) {
      let sampleColors = [
        "#0000FF",
        "#FF0000",
        "#00CC00",
        "#FF00FF",
        "#9F9F00",
        "#00CCCC",
        "#FF7F00",
        "#007FFF",
        "#FF007F",
        "#7FFF7F",
        "#7F7FFF",
        "#FF7F7F",
      ];
      // 检查索引
      if (idx < sampleColors.length) {
        return sampleColors[idx];
      }

      // 返回黑色
      return "#000000";
    },
    // 添加曲线数据
    addCurveData: function () {
      // 设置当前选中的控件
      let ctrl = this.getCurrentCtrl();
      let vColor = "";
      let vMinVal = 0;
      let vMaxVal = 100;
      // 检查是否有效
      if (null != this.listAllCurves) {
        // 获取颜色值
        vColor = this.getCurveSampleColor(this.listAllCurves.length);
        // 是否为饼图
        if (ctrl.type === HG.ControlType.pies) {
          // 设置最大值
          vMaxVal = 65535;
        }
        // 添加项目
        this.listAllCurves.push({
          selected: false,
          idx: this.listAllCurves.length,
          param: null,
          text: "数据" + this.listAllCurves.length,
          color: vColor,
          min: vMinVal,
          max: vMaxVal,
        });
      }
    },
    // 移除选中项目列表
    removeLstData: function () {
      // 选中项目
      let selectedCnt = 0;
      let newLstData = [];
      let idx = 0;
      // 检查是否存在选中
      for (idx = 0; idx < this.listAllCurves.length; idx++) {
        // 检查是否存在选中
        if (this.listAllCurves[idx].selected) {
          // 选中项目
          selectedCnt++;
        } else {
          // 添加到新列表
          newLstData.push(this.listAllCurves[idx]);
        }
      }
      // 重置
      this.listAllCurves = newLstData;
      // 重置索引值
      for (idx = 0; idx < this.listAllCurves.length; idx++) {
        this.listAllCurves[idx].idx = idx;
      }
      // 检查选中项目
      if (0 === selectedCnt) {
        // 询问删除
        this.$alert("要删除，请至少选择一项。", "项目删除", {
          confirmButtonText: "确定",
          type: "warning",
        });
      }
    },
    // 获取变量类型
    getVariableOptions: function () {
      // 数据
      return HMIPlan.getAllData();
    },
    // 确定
    submit: function () {
      // 设置当前选中的控件
      let ctrl = this.getCurrentCtrl();
      let allData = this.getCurvesData();

      // 检查页面是否有效
      if (ctrl) {
        // 检查控件类型
        if (ctrl.type === HG.ControlType.curves) {
          // 设置数据
          ctrl.curvesData = allData;
        } else if (ctrl.type === HG.ControlType.columns) {
          // 设置数据
          ctrl.columnsData = allData;
        } else if (ctrl.type === HG.ControlType.pies) {
          // 设置数据
          ctrl.piesData = allData;
        }
      }
      // 数据改变
      this.$emit("paramsDataChanged");
      // 隐藏对话框
      this.dlgVisible = false;
    },
  },
};
</script>
