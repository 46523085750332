<template>
  <div>
    <div v-for="attribute in getAllPageAttributes()" :key="attribute.name">
      <div class="attrName">{{ attribute.display }}</div>
      <div class="attrValue">
        <HMIAttributes
          v-bind:type="attribute.type"
          v-bind:name="attribute.name"
          v-bind:value="getValue(attribute.name)"
          v-on:valuechanged="changed"
          v-on:outCaller="outCaller"
        ></HMIAttributes>
      </div>
    </div>
  </div>
</template>
<script>
let that
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
// 导入组件
import HMIAttributes from "./HMIAttributes.vue";
// 当前组件属性
export default {
  // 函数
  methods: {
    // 获取所有页面属性
    getAllPageAttributes: function () {
      return HG.$Set.allAttributes.pageAttributes;
    },
    // 获取内容
    getValue: function (name) {
      // 字符内容
      let data = null;
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      // 检查名称
      switch (name) {
        case HG.PageAttributeType.name:
          data = page.name;
          break;
        case HG.PageAttributeType.width:
          data = HMIPrj.vHMIPrj.setting.width;
          break;
        case HG.PageAttributeType.height:
          data = HMIPrj.vHMIPrj.setting.height;
          break;
        case HG.PageAttributeType.bgColor:
          data = page.bgColor;
          break;
        case HG.PageAttributeType.bgImage:
          data = page.bgImage;
          break;
      }
      return data;
    },
    // 内容改变
    changed: function (name, value) {
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      // 检查名称
      switch (name) {
        case HG.PageAttributeType.name:
          // 检查是否一致
          if (page.name != value) {
            if (value.length>0&&value.length<=12) {
              // 页面名称改变
            page.name = value;
            }else{
               that.$message({
                type: "warning",
                message: "页面名字长度需小于12位！",
              });
            }
            
          }
          break;
        case HG.PageAttributeType.width:
          HMIPrj.vHMIPrj.setting.width = value;
          break;
        case HG.PageAttributeType.height:
          HMIPrj.vHMIPrj.setting.height = value;
          break;
        case HG.PageAttributeType.bgColor:
          page.bgColor = value;
          break;
        case HG.PageAttributeType.bgImage:
          page.bgImage = value;
          break;
      }
      // 页面改变
      this.$emit("pagechanged");
    },
    // 回调外部调用设置选择框
    outCaller: function (type, name) {
      // 回调外部调用设置选择框
      this.$emit("outCaller", type, name);
    },
  },
  mounted(){
    that=this;
  },
  components: {
    HMIAttributes,
  },
};
</script>
