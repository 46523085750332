<template>
  <div>
    <div v-for="layout in allLayout()" :key="layout.idx" class="layoutDiv">
      <img
        class="alignItem"
        v-bind:src="layout.image"
        v-bind:title="layout.name"
        v-bind:alt="layout.name"
        v-on:mouseover="getMenuOperate().alignHover()"
        v-on:mouseout="getMenuOperate().alignRestore()"
        v-on:click="setCtrlsLayout(layout.type)"
      />
    </div>
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIMenu from "../util/draw/HMIMenu.js";
// 当前组件属性
export default {
  // 函数
  methods: {
    // 获取菜单操作
    getMenuOperate: function () {
      return HMIMenu;
    },
    // 获取布局工具按钮
    allLayout: function () {
      // 获取布局工具按钮
      return HG.$Set.allLayout;
    },
    // 设置选中控件布局
    setCtrlsLayout: function (type) {
      // 检查是否设置全局设置
      HMIPrj.vHMIPrjFunc.gs = HG;
      // 选中控件布局
      HMIPrj.vHMIPrjFunc.setCtrlsLayout(type);
    },
  },
};
</script>
