<template>
  <div class="attrFrame">
    <!-- 通常输入 -->
    <input
      type="text"
      v-model="dataValue"
      v-on:change="dataChanged"
      v-bind:readonly="inputReadonly()"
      v-bind:class="inputCss()"
      v-if="isInput()"
    />
    <!-- 颜色选择器显示 -->
    <div class="toolArea" v-if="isColor()">
      <el-color-picker
        v-on:change="dataChanged"
        v-model="dataValue"
        size="mini"
        show-alpha
        v-if="isColor()"
        :predefine="constant.predefineColors"
      ></el-color-picker>
    </div>
    <!-- 变量参数显示 -->
    <el-cascader
      v-model="dataValue"
      v-on:change="dataChanged"
      :options="getVariableOptions()"
      v-if="isVariable()"
      :props="{ multiple: isMultiple(), checkStrictly: true }"
      :show-all-levels="false"
      size="mini"
      collapse-tags
    ></el-cascader>
    <!-- 选择类型 -->
    <select
      v-model="dataValue"
      v-on:change="dataChanged"
      class="attr"
      v-if="isSelector()"
    >
      <option
        v-for="item in getSelectorOptions()"
        :key="item.key"
        :value="item.key"
      >
        {{ item.text }}
      </option>
    </select>
    <!-- 外部调用显示 -->
    <div class="toolArea" v-if="isOutCaller()">
      <el-button
        :icon="getOutCallerIcon()"
        size="mini"
        v-on:click="outCaller"
        v-if="isOutCaller()"
        circle
      ></el-button>
    </div>
  </div>
</template>

<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIPlan from "../util/draw/HMIPlanData.js";
import constant from "../util/constant";
import HMIDraw from "../util/draw/HMIDraw.js";
// 当前组件属性
export default {
  // 组件名
  name: "HMIAttributes",
  // 属性
  props: ["ctrl", "type", "name", "value"],
  // 创建
  created: function () {},
  // 数据
  data: function () {
    return {
      // 数据值
      dataValue: this.value,
      constant: constant,
    };
  },
  // 函数
  methods: {
    setDisabled(newAllData) {
      newAllData.forEach(item=>{
          item["disabled"]=true;
          item.children.forEach(ele=>{
            ele["disabled"]=true;
              ele.children.forEach(element=>{
                if (this.dataValue.length>=5) {
                  element["disabled"]=true;
                  this.dataValue.forEach(checkItem=>{
                  //  console.log(checkItem,element);
                    if (checkItem[2]==element.value &&checkItem[1]==ele.value&&checkItem[0]==item.value) {
                      element["disabled"]=false;
                    }
                  })
                }else{
                  element["disabled"]=false;
                }
              })
            
          })
        })
      
      
    },
    // 内容改变
    dataChanged: function () {
      HMIDraw.valueNew=true;
      // 内容改变
      this.$emit("valuechanged", this.name, this.dataValue);
    },
    // 输入框只读状态
    inputReadonly: function () {
      // 检查属性类型
      //海波测试
      if (this.$store.state.platType == 'pc') {
         if (
        this.type === HG.AttrType.color ||
        this.type === HG.AttrType.image ||
        this.name == "width" ||
        this.name == "height"
      ) {
        return true;
      }
      }else{
          if (
        this.type === HG.AttrType.color ||
        this.type === HG.AttrType.image 
      ) {
        return true;
      }
      }
     

      // if (this.type === HG.AttrType.color || this.type === HG.AttrType.image) {
      //   return true;
      // }
      return false;
    },
    // 输入框样式
    inputCss: function () {
      // 输入框样式
      if (this.inputReadonly()) {
        return "attrR";
      }
      return "attrW";
    },
    // 是否为输入框
    isInput: function () {
      // 普通输入类型，不是其他类型
      switch (this.type) {
        case HG.AttrType.int:
        case HG.AttrType.color:
        case HG.AttrType.string:
        case HG.AttrType.image:
          return true;
        default:
          return false;
      }
    },
    // 是否为颜色汲取
    isColor: function () {
      // 检查属性类型
      return this.type === HG.AttrType.color;
    },
    //是否支持多选参数
    isMultiple: function () {
      return this.ctrl.multiple;
    },
    // 是否为变量参数类型
    isVariable: function () {
      // 变量类型
      return this.type === HG.AttrType.variable;
    },
    // 是否为外部调用设置属性
    isOutCaller: function () {
      // 检查属性类型
      if (this.type === HG.AttrType.image) return true;
      if (this.type === HG.AttrType.textLstData) return true;
      if (this.type === HG.AttrType.paramsData) return true;
      if (this.type === HG.AttrType.dataTable) return true;
      // 不是选择器类型
      return false;
    },
    // 获取外部调用设置的图标
    getOutCallerIcon: function () {
      // 检查属性类型
      if (this.type === HG.AttrType.image) return "el-icon-picture";
      // 通用的设置图标
      return "el-icon-s-tools";
    },
    // 回调外部调用设置选择框
    outCaller: function () {
      // 回调外部图片选择框
      this.$emit("outCaller", this.type, this.name);
    },
    // 获取变量类型
    getVariableOptions: function () {
      // 数据

      let newAllData = HMIPlan.getAllData().slice();
      this.dealOptions(newAllData);
      this.setDisabled(newAllData);
      return newAllData;
    },
    dealOptions: function (data) {
      
      for (let i = 0; i < data.length; i++) {
        if (data[i].children) {
          if (data[i].children.length > 0) {
            this.dealOptions(data[i].children);
          }
        } else {
          if (data[i].paramTypeId) {
            if (this.ctrl.type == 6 || this.ctrl.type == 8) {
              if (data[i].paramTypeId != 2) {
                data.splice(i, 1);
                i--;
              }
            } else if (this.ctrl.type == 9) {
              if (data[i].paramTypeId != 1 && data[i].paramTypeId != 3) {
                data.splice(i, 1);
                i--;
              }
            }
          }
        }
      }
    },
    // 是否为选择器类型
    isSelector: function () {
      // 检查属性类型
      if (this.type === HG.AttrType.bool) return true;
      if (this.type === HG.AttrType.fontsize) return true;
      if (this.type === HG.AttrType.lineType) return true;
      if (this.type === HG.AttrType.timeType) return true;
      if (this.type === HG.AttrType.bbtnValueSet) return true;
      if (this.type === HG.AttrType.wbtnValueSet) return true;
      if (this.type === HG.AttrType.linkPage) return true;
      if (this.type === HG.AttrType.viewStatus) return true;
      if (this.type === HG.AttrType.progressBarMode) return true;
      if (this.type === HG.AttrType.optPermissionMode) return true;
      // 不是选择器类型
      return false;
    },
    // 获取选择器的选项
    getSelectorOptions: function () {
      // 检查属性类型
      if (this.type === HG.AttrType.bool) return HG.AttrSelTypeDesc.boolOptions;
      if (this.type === HG.AttrType.fontsize)
        return HG.AttrSelTypeDesc.fontSizeOptions;
      if (this.type === HG.AttrType.lineType)
        return HG.AttrSelTypeDesc.lineTypeOptions;
      if (this.type === HG.AttrType.timeType)
        return HG.AttrSelTypeDesc.timeTypeOptions;
      if (this.type === HG.AttrType.bbtnValueSet)
        return HG.AttrSelTypeDesc.bbtnValueSetTypeOptions;
      if (this.type === HG.AttrType.wbtnValueSet)
        return HG.AttrSelTypeDesc.wbtnValueSetTypeOptions;
      if (this.type === HG.AttrType.linkPage) return this.getLinkPagesOptions();
      if (this.type === HG.AttrType.viewStatus)
        return this.getViewStatusOptions();
      // 进度条模式
      if (this.type === HG.AttrType.progressBarMode)
        return HG.AttrSelTypeDesc.progressBarModeOptions;
      if (this.type === HG.AttrType.optPermissionMode)
        return HG.AttrSelTypeDesc.optPermissionModeOptions;
      // 不是选择器类型
      return [];
    },
    // 获取专用选择器：页面跳转
    getLinkPagesOptions: function () {
      // 添加到页面数组
      let linkPgs = [];
      // 获取所有页面
      for (let idx = 0; idx < HMIPrj.vHMIPrj.allPg.length; idx++) {
        // 添加数组项
        linkPgs.push({
          key: HMIPrj.vHMIPrj.allPg[idx].idx,
          text: HMIPrj.vHMIPrj.allPg[idx].name,
        });
      }
      // 返回所有的页面
      return linkPgs;
    },
    // 获取专用选择器：预览状态显示
    getViewStatusOptions: function () {
      let viewStatus = [];
      let idx = 0;
      // 检查控件类型
      switch (this.ctrl.type) {
        case HG.ControlType.light:
        case HG.ControlType.bbtn:
          // 添加数组项
          viewStatus.push({ key: 0, text: "状态0" });
          viewStatus.push({ key: 1, text: "状态1" });
          return viewStatus;
        case HG.ControlType.textLst:
          // 添加数组项
          for (idx = 0; idx < this.ctrl.listValue.length; idx++) {
            viewStatus.push({ key: idx, text: "状态" + idx });
          }
          // 其他情况添加
          viewStatus.push({ key: -1, text: "其他情况" });
          return viewStatus;
        // 图片数值对应控件的显示状态
        case HG.ControlType.pictureList:
          // 添加数组项
          for (idx = 0; idx < this.ctrl.listValue.length; idx++) {
            viewStatus.push({ key: idx, text: "状态" + idx });
          }
          // 其他情况添加
          viewStatus.push({ key: -1, text: "其他情况" });
          return viewStatus;
        default:
          return viewStatus;
      }
    },
  },
};
</script>
