<template>
  <div style="max-height:360px;overflow-y: auto;">
    <div v-for="page in allPages()" :key="page.idx" class="menuItemDiv">
      <span class="pageIcon"></span>
      <span class="menuItemText" v-on:click="selectedPage(page.idx)">{{
        page.name
      }}</span>
      <span class="pageDelCmd" v-on:click="deletePage(page.idx)"></span>
      <span class="pageCopyCmd" v-on:click="copyPage(page.idx)"></span>
    </div>
    <div class="menuItemDiv">
      <span class="pageAddIcon"></span>
      <span class="menuItemText" v-on:click="newPage()">新增页面</span>
    </div>
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
// 当前组件属性
export default {
  // 函数
  methods: {
    // 获取所有页面
    allPages: function () {
      // 获取页面
      return HMIPrj.vHMIPrj.allPg;
    },
    // 选中当前页面
    selectedPage: function (pageIdx) {
      // 检查是否为当前页面
      if (pageIdx != HMIPrj.vHMIPrj.curPg) {
        // 设置当前选中的页面
        HMIPrj.vHMIPrj.curPg = pageIdx;
        // 页面改变
        this.pageChanged();
      }
    },
    // 复制指定页面
    copyPage: function (pageIdx) {
      let vPage         = HMIPrj.vHMIPrj.allPg[pageIdx];
      let vPageName     = '';
      let vPageNameOld  = '';
      let vPageNameLen  = 0;
      let vPageCur      = null;
      let vIdx          = 0;
      let vCopyCnt      = 0;

      // 检查页面是否存在
      if (vPage) {
        // 转换页面数据
        vPage = JSON.parse(JSON.stringify(vPage));
        // 设置索引
        vPage.idx = HMIPrj.vHMIPrj.allPg.length;
        // 页面名称
        vPageName = vPage.name + '-副本';
        // 名称长度
        vPageNameLen = vPageName.length;

        // 比较是否存在
        for(vIdx = 0; vIdx < HMIPrj.vHMIPrj.allPg.length; vIdx++) {
          // 获取
          vPageCur = HMIPrj.vHMIPrj.allPg[vIdx];
          // 获取旧页面名称
          vPageNameOld = vPageCur.name;
          // 检查字符长度
          if(vPageNameOld.length > vPageNameLen) {
            // 获取旧页面名称
            if(vPageNameOld.substr(0, vPageNameLen) == vPageName) {
              // 获取后半
              vPageNameOld = vPageNameOld.substr(vPageNameLen);
              // 检查是否为字符
              if(parseInt(vPageNameOld)) {
                // 检查副本
                if(vCopyCnt < parseInt(vPageNameOld)) {
                  // 设置
                  vCopyCnt = parseInt(vPageNameOld);
                }
              }
            }
          }
        }
        // 设置副本名称
        vPage.name = vPageName + (vCopyCnt+1).toString();
        // 添加页面
        HMIPrj.vHMIPrj.allPg.push(vPage);

        // 页面改变
        this.pageChanged();
      }
    },
    // 删除指定页面
    deletePage: function (pageIdx) {
      // 检查是否最后一个页面
      if (HMIPrj.vHMIPrj.allPg.length <= 1) {
        // 消息
        this.$alert("不能删除最后一个页面！", "页面删除", {
          confirmButtonText: "确定",
          type: "warning",
        });
        return;
      }
      // 检查是否最后一个页面
      if (pageIdx >= HMIPrj.vHMIPrj.allPg.length) {
        // 消息
        this.$alert("删除页面不存在！", "页面删除", {
          confirmButtonText: "确定",
          type: "warning",
        });
        return;
      }
      // 询问是否要删除
      this.$confirm(
        "是否要删除页面: [" + HMIPrj.vHMIPrj.allPg[pageIdx].name + "] ？",
        "页面删除",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // 当前页面
          let currentPage = HMIPrj.vHMIPrj.curPg;
          let idx = 0;
          // 循环设置数组
          for (idx = 0; idx < HMIPrj.vHMIPrj.allPg.length - 1; idx++) {
            // 检查开始
            if (HMIPrj.vHMIPrj.allPg[idx].idx >= pageIdx) {
              // 设置偏移
              HMIPrj.vHMIPrj.allPg[idx] = HMIPrj.vHMIPrj.allPg[idx + 1];
            }
          }
          // 移除数组
          HMIPrj.vHMIPrj.allPg.pop();
          // 循环数组重置索引
          for (idx = 0; idx < HMIPrj.vHMIPrj.allPg.length; idx++) {
            // 设置索引
            HMIPrj.vHMIPrj.allPg[idx].idx = idx;
          }
          // 当前选中页面
          if (currentPage == pageIdx) {
            // 设置为第一页面
            HMIPrj.vHMIPrj.curPg = 0;
          }
          // 检查页面
          else if (currentPage > pageIdx) {
            // 修改页面索引
            HMIPrj.vHMIPrj.curPg--;
          }
          // 页面改变
          this.pageChanged();
        })
        .catch(() => {
          // 取消删除
        });
    },
    // 新增页面
    newPage: function () {
      // 获取新页面
      var newPage = JSON.parse(JSON.stringify(HG.$Set.allPagesData[0]));
      // 设置索引名称
      newPage.idx =
        HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.allPg.length - 1].idx + 1;
      newPage.name = "新页面" + (newPage.idx + 1);
      // 设置当前选中的页面
      HMIPrj.vHMIPrj.allPg.push(newPage);
      // 设置当前选中的页面
      HMIPrj.vHMIPrj.curPg = newPage.idx;
      // 页面改变
      this.pageChanged();
    },
    // 页面状态改变
    pageChanged: function () {
      this.$emit("pagechanged");
    },
  },
};
</script>
