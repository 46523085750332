<template>
  <div>
    <div class="attrName">控件类型</div>
    <div class="attrValue">
      <div class="attrFrame">
        <input
          type="text"
          v-model="controlType"
          readonly="true"
          class="attrRL"
        />
      </div>
    </div>
    <div v-for="attribute in getCtrlAttributes()" :key="attribute.name">
      <div class="attrName">{{ attribute.display }}</div>
      <div class="attrValue">
        <HMIAttributes
          v-bind:ctrl="currentCtrl"
          v-bind:type="attribute.type"
          v-bind:name="attribute.name"
          v-bind:value="getValue(attribute.name)"
          v-on:valuechanged="changed"
          v-on:outCaller="outCaller"
        ></HMIAttributes>
      </div>
    </div>
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
// 导入组件
import HMIAttributes from "./HMIAttributes.vue";
import HMIPlanData from "../util/draw/HMIPlanData";
import HMIInterface from "../util/draw/HMIInterface";
// 当前组件属性
export default {
  // 数据
  data: function () {
    return {
      // 当前控件
      currentCtrl: null,
      // 控件类型
      controlType: "",
    };
  },
  // 创建
  created: function () {
    // 控件类型
    this.controlType = this.getCtrlTypeName();
  },
  // 函数
  methods: {
    // 获取当前控件
    getCurrentControl: function () {
      // 页面与索引
      let page = null;
      let idx = 0;
      // 检查是否存在
      if (this.currentCtrl == null) {
        // 当前页面
        page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
        // 获取当前选中的控件
        for (idx = 0; idx < page.ctrls.length; idx++) {
          // 控件是否选中
          if (page.ctrls[idx].selected) {
            // 当前控件
            this.currentCtrl = page.ctrls[idx];
            // HMIPlanData.isThree = this.currentCtrl.type == 12 ? true : false;
            switch (this.currentCtrl.type) {
              case 6:
              case 8:
              case 9:
              case 12:
              case 13:
              case 17:
              case 19:
              case 20:
              case HG.ControlType.progressBar:
              case HG.ControlType.pictureList:
                HMIPlanData.isThree = true;
                break;
              default:
                HMIPlanData.isThree = false;
                break;
            }
            // HMIPlanData.getAllData();

            break;
          }
        }
      }
      // 检查当前控件是否存在
      return this.currentCtrl;
    },
    // 获取控件类型名称
    getCtrlTypeName: function () {
      // 获取当前控件
      let ctrl = this.getCurrentControl();
      let idx = 0;
      // 检查当前控件
      if (ctrl) {
        // 获取当前选中的控件
        for (idx = 0; idx < HG.$Set.allcontrols.length; idx++) {
          // 控件是否选中
          if (ctrl.type === HG.$Set.allcontrols[idx].type) {
            // 当前控件的名称
            return HG.$Set.allcontrols[idx].name;
          }
        }
      }
      // 错误类型
      return "";
    },
    // 获取当前选中控件的属性
    getCtrlAttributes: function () {
      // 获取当前控件
      let ctrl = this.getCurrentControl();
      let ctrlType = HG.ControlType.error;
      let idx = 0;
      let attributes = null;

      // 检查当前控件
      if (ctrl) {
        // 获取控件类型
        ctrlType = ctrl.type;
        // 检查控件类型
        if (ctrlType != HG.ControlType.error) {
          // 获取属性
          for (
            idx = 0;
            idx < HG.$Set.allAttributes.ctrlAttributes.length;
            idx++
          ) {
            // 获取控件显示属性
            if (ctrlType === HG.$Set.allAttributes.ctrlAttributes[idx].type) {
              // 获取属性数组
              HMIInterface.searchParams(
                HG.$Set.allCtrlsData[
                  HG.$Set.allAttributes.ctrlAttributes[idx].type - 1
                ].interfaceId
              );
              attributes = HG.$Set.allAttributes.ctrlAttributes[idx].attributes;
              // 退出
              break;
            }
          }
        }
      }
      return attributes;
    },
    // 获取内容
    getValue: function (name) {
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      let ctrl = null;
      let idx = 0;
      // 获取当前选中的控件
      for (idx = 0; idx < page.ctrls.length; idx++) {
        // 控件是否选中
        if (page.ctrls[idx].selected) {
          // 控件类型
          ctrl = page.ctrls[idx];
          break;
        }
      }
      // 检查控件是否存在
      if (ctrl) {
        // 遍历key
        for (var key in ctrl) {
          // 检查键值
          if (key === name) {
            // 获取值
            return ctrl[key];
          }
        }
      }
      // 空值
      return "";
    },
    // 内容改变
    changed: function (name, value) {
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      let ctrl = null;
      let idx = 0;
      let validSet = false;
      // 获取当前选中的控件
      for (idx = 0; idx < page.ctrls.length; idx++) {
        // 控件是否选中
        if (page.ctrls[idx].selected) {
          // 控件类型
          ctrl = page.ctrls[idx];
          break;
        }
      }
      // 检查控件是否存在
      if (ctrl) {
        // 遍历key
        for (var key in ctrl) {
          // 检查键值
          if (key === name) {
            // 选中控件的属性改变
            HMIPrj.vHMIPrjFunc.selectedCtrlsModifyAttributes(true);
            // 获取值       
            ctrl[key] = value;
            // 找到属性并设置
            validSet = true;
            // 控件属性改变
            this.$emit("ctrlchanged");
            break;
          }
        }
        // 如果没有找到此属性时, 添加此属性
        if (!validSet) {
          // 选中控件的属性改变
          HMIPrj.vHMIPrjFunc.selectedCtrlsModifyAttributes(true);
          // 获取值       
          ctrl[name] = value;
          // 控件属性改变
          this.$emit("ctrlchanged");
        }
      }
    },
    // 回调外部调用设置选择框
    outCaller: function (type, name) {
      // 回调外部调用设置选择框
      this.$emit("outCaller", type, name);
    },
  },
  components: {
    HMIAttributes,
  },
};
</script>
