<template>
  <div>
    <div v-for="attribute in getAllSysPermissionAttributes()" :key="attribute.name">
      <div class="attrName">{{ attribute.display }}</div>
      <div class="attrValue">
        <HMIAttributes
          v-bind:type="attribute.type"
          v-bind:name="attribute.name"
          v-bind:value="getValue(attribute.name)"
          v-on:valuechanged="changed"
          v-on:outCaller="outCaller"
        ></HMIAttributes>
      </div>
    </div>
  </div>
</template>
<script>
let that
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
// 导入组件
import HMIAttributes from "./HMIAttributes.vue";
// 当前组件属性
export default {
  // 函数
  methods: {
    // 获取系统权限
    getAllSysPermissionAttributes: function () {
      return HG.$Set.allAttributes.sysPermissionsAttributes;
    },
    // 获取内容
    getValue: function (name) {
      // 系统权限数组
      let sysPermission = null;
      // 字符内容
      let data = null;

      // 检查系统权限是否有效
      if (HMIPrj.vHMIPrj.sysPermissions) {
         // 检查数组长度
         if (HMIPrj.vHMIPrj.sysPermissions.length > 0) {
          // 系统权限数组
          sysPermission = HMIPrj.vHMIPrj.sysPermissions;
         }
      }
      // 检查名称
      switch (name) {
        case HG.SystemPermissionsAttributeType.sysPwd1:
          // 获取系统权限默认值
          data = '1111';
          // 检查数据
          if (sysPermission) {
            // 检查长度
            if(sysPermission.length > 0) {
              // 获取系统权限
              data = sysPermission[0];
            }
          }
          break;
        case HG.SystemPermissionsAttributeType.sysPwd2:
          // 获取系统权限默认值
          data = '2222';
          // 检查数据
          if (sysPermission) {
            // 检查长度
            if(sysPermission.length > 1) {
              // 获取系统权限
              data = sysPermission[1];
            }
          }
          break;
        case HG.SystemPermissionsAttributeType.sysPwd3:
          // 获取系统权限默认值
          data = '3333';
          // 检查数据
          if (sysPermission) {
            // 检查长度
            if(sysPermission.length > 2) {
              // 获取系统权限
              data = sysPermission[2];
            }
          }
          break;
        case HG.SystemPermissionsAttributeType.sysPwd4:
          // 获取系统权限默认值
          data = '4444';
          // 检查数据
          if (sysPermission) {
            // 检查长度
            if (sysPermission.length > 3) {
              // 获取系统权限
              data = sysPermission[3];
            }
          }
          break;
        case HG.SystemPermissionsAttributeType.sysPwd5:
          // 获取系统权限默认值
          data = '5555';
          // 检查数据
          if (sysPermission) {
            // 检查长度
            if (sysPermission.length > 4) {
              // 获取系统权限
              data = sysPermission[4];
            }
          }
          break;
        case HG.SystemPermissionsAttributeType.sysPwd6:
          // 获取系统权限默认值
          data = '6666';
          // 检查数据
          if (sysPermission) {
            // 检查长度
            if (sysPermission.length > 5) {
              // 获取系统权限
              data = sysPermission[5];
            }
          }
          break;
      }
      return data;
    },
    // 内容改变
    changed: function (name, value) {
      // 检查名称
      switch (name) {
        case HG.SystemPermissionsAttributeType.sysPwd1:
          // 设置系统权限
          HMIPrj.vHMIPrj.sysPermissions[0] = value;
          break;
        case HG.SystemPermissionsAttributeType.sysPwd2:
          // 设置系统权限
          HMIPrj.vHMIPrj.sysPermissions[1] = value;
          break;
        case HG.SystemPermissionsAttributeType.sysPwd3:
          // 设置系统权限
          HMIPrj.vHMIPrj.sysPermissions[2] = value;
          break;
        case HG.SystemPermissionsAttributeType.sysPwd4:
          // 设置系统权限
          HMIPrj.vHMIPrj.sysPermissions[3] = value;
          break;
        case HG.SystemPermissionsAttributeType.sysPwd5:
          // 设置系统权限
          HMIPrj.vHMIPrj.sysPermissions[4] = value;
          break;
        case HG.SystemPermissionsAttributeType.sysPwd6:
          // 设置系统权限
          HMIPrj.vHMIPrj.sysPermissions[5] = value;
          break;
      }
      // 页面改变
      this.$emit("pagechanged");
    },
    // 回调外部调用设置选择框
    outCaller: function (type, name) {
      // 回调外部调用设置选择框
      this.$emit("outCaller", type, name);
    },
  },
  mounted(){
    that=this;
  },
  components: {
    HMIAttributes,
  },
};
</script>
