<template>
  <el-dialog
    title="文数对应项目编辑"
    top="60px"
    :modal="false"
    :visible.sync="dlgVisible"
    width="500px"
  >
    <div class="grid-main">
      <el-row :gutter="3">
        <el-col :span="2"><div class="grid-title">选择</div></el-col>
        <el-col :span="4"><div class="grid-title">数据值</div></el-col>
        <el-col :span="12"><div class="grid-title">显示字符</div></el-col>
        <el-col :span="6"><div class="grid-title">颜色</div></el-col>
      </el-row>
      <div v-for="item in getListData()" :key="item.value" class="grid-row">
        <el-row :gutter="6">
          <el-col :span="2"
            ><div class="grid-cmd">
              <el-checkbox v-model="item.selected"></el-checkbox></div
          ></el-col>
          <el-col :span="4"
            ><div class="grid-cmd">{{ item.value }}</div></el-col
          >
          <el-col :span="12">
            <div class="grid-content">
              <input type="text" v-model="item.text" class="attrWL" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-color-text">
              <input
                type="text"
                v-model="item.color"
                readonly="true"
                class="attrRSF"
              />
            </div>
            <div class="grid-color">
              <el-color-picker
                v-model="item.color"
                size="mini"
                :predefine="constant.predefineColors"
              ></el-color-picker>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grid-foot">
      <el-row :gutter="3">
        <el-col :span="5"><div class="grid-title">错误情况字符</div></el-col>
        <el-col :span="12">
          <div class="grid-content">
            <input type="text" v-model="getOtherData().text" class="attrWL" />
          </div>
        </el-col>
        <el-col :span="2"><div class="grid-title">颜色</div></el-col>
        <el-col :span="3">
          <div class="grid-text">
            <input
              type="text"
              v-model="getOtherData().color"
              readonly="true"
              class="attrRS"
            />
          </div>
        </el-col>
        <el-col :span="2">
          <div class="grid-color">
            <el-color-picker
              v-model="getOtherData().color"
              size="mini"
              :predefine="constant.predefineColors"
            ></el-color-picker>
          </div>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addListData()" size="mini">新增行</el-button>
      <el-button @click="removeLstData()" size="mini">删除行</el-button>
      <el-button @click="dlgVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit()" size="mini"
        >保存退出</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import constant from "../util/constant";

// 当前组件属性
export default {
  // 属性
  props: ["visible"],
  // 数据
  data: function () {
    return {
      constant: constant,
      // 对话框是否显示
      dlgVisible: this.visible,
      // 当前控件指针
      currentCtrl: null,
      // 列表所有数据
      listAllData: [],
      // 列表其他数据
      listOtherData: null,
    };
  },
  // 函数
  methods: {
    // 获取当前选择的控件
    getCurrentCtrl: function () {
      // 设置当前选中的页面
      let page = null;
      // 检查当前控件指针是否已经获取
      if (null === this.currentCtrl) {
        // 设置当前选中的页面
        page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
        // 检查是否选中控件
        for (let idx = 0; idx < page.ctrls.length; idx++) {
          // 获取选中控件
          if (page.ctrls[idx].selected) {
            // 获取控件
            this.currentCtrl = page.ctrls[idx];
            // 跳出
            break;
          }
        }
      }
      // 当前选中控件
      return this.currentCtrl;
    },
    // 获取所有的列表
    getListData: function () {
      // 检查是否获取了控件中的数据列表
      if (this.listAllData.length === 0) {
        // 设置当前选中的控件
        let ctrl = this.getCurrentCtrl();
        let listItem = null;
        // 检查控件是否存在
        if (null != ctrl) {
          // 检查控件类型
          if (ctrl.type === HG.ControlType.textLst) {
            // 检查是否选中控件
            for (let idx = 0; idx < ctrl.listValue.length; idx++) {
              // 获取当前项目
              listItem = ctrl.listValue[idx];
              // 添加项目
              this.listAllData.push({
                selected: false,
                value: listItem.value,
                text: listItem.text,
                color: listItem.color,
              });
            }
            // 空内容
            if (this.listAllData.length === 0) {
              // 添加到项目列表
              this.addListData();
              this.addListData();
              this.addListData();
            }
          }
        }
      }
      // 返回列表
      return this.listAllData;
    },
    // 添加到项目列表
    addListData: function () {
      // 检查是否有效
      if (null != this.listAllData) {
        // 添加项目
        this.listAllData.push({
          selected: false,
          value: this.listAllData.length,
          text: "数据值为:" + this.listAllData.length,
          color: "#000000",
        });
      }
    },
    // 移除选中项目列表
    removeLstData: function () {
      // 选中项目
      let selectedCnt = 0;
      let newLstData = [];
      let idx = 0;
      // 检查是否存在选中
      for (idx = 0; idx < this.listAllData.length; idx++) {
        // 检查是否存在选中
        if (this.listAllData[idx].selected) {
          // 选中项目
          selectedCnt++;
        } else {
          // 添加到新列表
          newLstData.push(this.listAllData[idx]);
        }
      }
      // 重置
      this.listAllData = newLstData;
      // 重置索引值
      for (idx = 0; idx < this.listAllData.length; idx++) {
        this.listAllData[idx].value = idx;
      }
      // 检查选中项目
      if (0 === selectedCnt) {
        // 询问删除
        this.$alert("要删除，请至少选择一项。", "项目删除", {
          confirmButtonText: "确定",
          type: "warning",
        });
      }
    },
    // 获取其他的数据
    getOtherData: function () {
      // 检查是否存在数据
      if (this.listOtherData === null) {
        // 设置当前选中的控件
        let ctrl = this.getCurrentCtrl();
        // 检查控件是否存在
        if (null != ctrl) {
          // 检查控件类型
          if (ctrl.type === HG.ControlType.textLst) {
            // 设置数据
            this.listOtherData = ctrl.listOther;
          }
        }
      }
      // 检查是否存在数据
      if (this.listOtherData === null) {
        // 还是为空时，创建临时的数据
        this.listOtherData = {
          text: "警告！数据错误，超出有限范围。",
          color: "#FF0000",
        };
      }
      // 返回列表
      return this.listOtherData;
    },
    // 确定
    submit: function () {
      // 设置当前选中的控件
      let ctrl = this.getCurrentCtrl();
      let allData = this.getListData();
      let otherData = this.getOtherData();

      // 检查页面是否有效
      if (ctrl && ctrl.type === HG.ControlType.textLst) {
        // 设置数据
        ctrl.listValue = allData;
        ctrl.listOther = otherData;
      }
      // 列表值属性改变
      this.$emit("textLstChanged");
      // 隐藏对话框
      this.dlgVisible = false;
    },
  },
};
</script>
