<template>
  <div>
    <div v-for="attribute in getPosAttributes()" :key="attribute.name">
      <div class="attrName">{{ attribute.display }}</div>
      <div class="attrValue">
        <HMIAttributes
          v-bind:type="attribute.type"
          v-bind:name="attribute.name"
          v-bind:value="getValue(attribute.name)"
          v-on:valuechanged="changed"
        ></HMIAttributes>
      </div>
    </div>
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
// 导入组件
import HMIAttributes from "./HMIAttributes.vue";
// 当前组件属性
export default {
  // 函数
  methods: {
    // 获取所有位置属性
    getPosAttributes: function () {
      return HG.$Set.allAttributes.posAttributes;
    },
    // 获取内容
    getValue: function (name) {
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      let ctrl = null;
      let idx = 0;
      // 获取当前选中的控件
      for (idx = 0; idx < page.ctrls.length; idx++) {
        // 控件是否选中
        if (page.ctrls[idx].selected) {
          // 控件类型
          ctrl = page.ctrls[idx];
          break;
        }
      }
      // 检查控件是否存在
      if (ctrl) {
        // 遍历key
        for (var key in ctrl) {
          // 检查键值
          if (key === name) {
            // 获取值
            return ctrl[key];
          }
        }
      }
      // 空值
      return "";
    },
    // 内容改变
    changed: function (name, value) {
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      let ctrl = null;
      let idx = 0;
      let data = 0;
      // 获取当前选中的控件
      for (idx = 0; idx < page.ctrls.length; idx++) {
        // 控件是否选中
        if (page.ctrls[idx].selected) {
          // 控件类型
          ctrl = page.ctrls[idx];
          break;
        }
      }
      // 检查控件是否存在
      if (ctrl) {
        // 遍历key
        for (var key in ctrl) {
          // 检查键值
          if (key === name) {
            // 数据
            data = parseInt(value);
            // 检查是否有效
            if (isNaN(data)) {
              // 数据设置默认值
              data = 0;
            }
            // 选中控件的属性改变
            HMIPrj.vHMIPrjFunc.selectedCtrlsModifyAttributes(true);
            // 获取值
            ctrl[key] = data;
            // 控件属性改变
            this.$emit("ctrlchanged");
            break;
          }
        }
      }
    },
  },
  components: {
    HMIAttributes,
  },
};
</script>
