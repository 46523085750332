<template>
  <div :key="toolbarMainArea" class="toolDiv">
    <template v-for="operate in allOperates()">
      <img
        :key="operate.idx"
        class="operateItem"
        v-bind:src="getExecOperateImage(operate)"
        v-bind:title="operate.name"
        v-bind:alt="operate.name"
        v-on:mouseover="getMenuOperate().toolHover()"
        v-on:mouseout="getMenuOperate().toolRestore()"
        v-on:click="execOperate(operate.type)"
      />
    </template>
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIMenu from "../util/draw/HMIMenu.js";
// import HMIInterface from "../util/draw/HMIInterface.js";
import $store from "../store/store";
import { baseUrl, addressUrl } from "../util/global";
// 当前组件属性
export default {
  // 组件名
  name: "HMIToolbar",
  // 数据
  data: function () {
    return {
      // 数据
      realData: 0,
      // 工具栏刷新
      toolbarMainArea: 0x10,
    };
  },
  // 函数
  methods: {
    // 获取所有操作
    allOperates: function () {
      // 获取
      return HG.$Set.allOperates;
    },
    // 检查是否可以执行
    getExecOperateImage: function (operate) {
      // 如果不允许执行时，返回灰色图片
      if (!HMIPrj.vHMIPrjFunc.canExecOperate(operate.type)) {
        // 不允许执行时返回灰色图片
        if (operate.imgGrey) {
          // 返回灰色图片
          return operate.imgGrey;
        }
      }
      // 返回标准图片
      return operate.image;
    },
    // 执行操作
    execOperate: function (type) {
      // 检查是否允许执行
      if (!HMIPrj.vHMIPrjFunc.canExecOperate(type)) {
        // 返回
        return;
      }
      baseUrl;
      // 执行操作
      HMIPrj.vHMIPrjFunc.execOperate(type);
      // 检查操作类型
      switch (type) {
        case HG.OperateType.view:
          // HMI Execute的直接登录
          // window.location.href =
          //   baseUrl +
          //   "/hmiexecute/" +
          //   HMIInterface.getLoginToken() +
          //   "/" +
          //   HMIInterface.planID.toString();
          window.open(
            `${addressUrl}#/hmiexecute/${$store.state.token}/${$store.state.tokenId}/${$store.state.pId}/${$store.state.platType}`
          );
          break;
        default:
          // 工具栏刷新
          this.toolbarMainArea ^= 0x01;
          break;
      }
    },
    // 获取菜单操作
    getMenuOperate: function () {
      return HMIMenu;
    },
  },
};
</script>
