<template>
  <div class="panel">
    <div
      class="sl"
      v-on:mouseover="getMenuOperate().menuHover()"
      v-on:mouseout="getMenuOperate().menuRestore()"
    >
      <a href="#" v-on:click="collapseMenu()">
        <span class="menuSign">-</span>
        {{ title }}
      </a>
    </div>
    <div v-show="detailDsp">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// 导入js
import HMIMenu from "../util/draw/HMIMenu.js";
// 当前组件属性
export default {
  // 属性
  props: ["title"],
  // 数据
  data: function () {
    return {
      // 显示状态
      detailDsp: true,
    };
  },
  // 函数
  methods: {
    // 获取菜单操作
    getMenuOperate: function () {
      return HMIMenu;
    },
    // 菜单折叠状态
    collapseMenu: function () {
      // 菜单状态设置
      HMIMenu.menuSelect();
      // 显示状态
      this.detailDsp = !this.detailDsp;
    },
  },
};
</script>
