<template>
  <div>
    <div
      v-for="ctrl in allCtrls()"
      :key="ctrl.idx"
      class="ctrlDiv"
      v-show="!($store.state.platType == 'app' && ctrl.name == '报警记录')"
    >
      <img
        class="ctrlItem"
        v-bind:src="ctrl.image"
        v-bind:title="ctrl.name"
        v-bind:alt="ctrl.name"
        v-on:mouseover="getMenuOperate().ctrlHover()"
        v-on:mouseout="getMenuOperate().ctrlRestore()"
        v-on:click="addCtrl(ctrl.type)"
      />
      <span>{{ctrl.name}}</span>
    </div>
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIMenu from "../util/draw/HMIMenu.js";
import HMIPlanData from "../util/draw/HMIPlanData.js";
// 当前组件属性
export default {
  // 函数
  methods: {
    // 获取菜单操作
    getMenuOperate: function () {
      return HMIMenu;
    },
    // 获取控件工具
    allCtrls: function () {
      // 获取控件工具
      return HG.$Set.allcontrols;
    },
    // 添加控件
    addCtrl: function (type) {
      // 检查是否设置全局设置
      HMIPrj.vHMIPrjFunc.gs = HG;
      // 添加控件
      HMIPrj.vHMIPrjFunc.addCtrl(type);
      HMIPlanData.pageAttrRefresh = !HMIPlanData.pageAttrRefresh;
      // HMIPlanData.isThree = type == 12 ? true : false;
      HMIPlanData.getAllData();
      switch (type) {
        case 12:
        case 17:
        case 19:
          HMIPlanData.isThree = true;
          break;
        default:
          HMIPlanData.isThree = false;
          break;
      }
    },
  },
};
</script>
